<!-- Footer Start -->
<footer id="contact" class=" iq-over-dark-90">
  <!-- <div class="footer-topbar">
      <div class="container">
          <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12 ">
                  <div class="widget">
                      <div class="textwidget">
                          <div class="row ">
                              <div class="col-lg-6 mb-5 mb-lg-0 text-center text-lg-left">
                                  <h2 class="text-white">Subscribe for Newsletter</h2>
                              </div>
                              <div class="col-lg-6 mb-5 mb-lg-0 text-center text-lg-right">
                                  <form class="mc4wp-form mc4wp-form-517" method="post">
                                      <div class="mc4wp-form-fields">
                                          <input type="email" name="EMAIL" placeholder="Your email address" required="">
                                          <input type="submit" value="Subscribe">
                                      </div>
                                  </form>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div> -->
  <!-- Address -->
  <!-- <div class="footer-top">
    <div class="container">
      <div class="row">
          <div class="col-lg-3 col-md-6 col-sm-6 "> -->
            <!-- <div class="widget">
              <div class="textwidget ">
                <p><img class="mb-4 img-fluid" src="{{ logoImg }}" alt="qloud">
                <br> It is a long established fact that a reader will be distracted by the readable content.</p>
              </div>
            </div> -->
          <!-- </div>
          <div class="col-lg-2 col-md-6 col-sm-6 mt-4 mt-lg-0 mt-md-0 ">
            <div class="widget">
              <h4 class="footer-title">Menu</h4>
              <div>
                <ul class="menu">
                  <li><a [routerLink]="['/landing-page1']" aria-current="page">Home</a></li>
                  <li><a [routerLink]="['/about-us-1']">About Us 1</a></li> -->
                  <!-- <li><a [routerLink]="['/services']">Service</a></li> -->
                  <!-- <li><a [routerLink]="['/blog']">Blog</a></li> -->
                  <!-- <li><a [routerLink]="['/contact-us-1']">Contact Us 1</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-6 col-sm-6 mt-lg-0 mt-4">
            <div class="widget">
              <h4 class="footer-title ">About Us</h4>
              <div class="menu-about">
                <ul id="menu-about-menu" class="menu"> -->
                  <!-- <li><a [routerLink]="['/about-us-2']">About Us 2</a></li> -->
                  <!-- <li><a [routerLink]="['/services']">Service</a></li> -->
                  <!-- <li><a [routerLink]="['/contact-us-2']">Contact Us 2</a></li>
                  <li><a [routerLink]="['/pricing-plan']">Pricing Plan</a></li> -->
                  <!-- <li><a [routerLink]="['/clients']">Clients</a></li> -->
                <!-- </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-6 col-sm-6 mt-lg-0 mt-4">
            <div class="widget">
              <h4 class="footer-title ">Useful links</h4>
              <div class="menu-link">
                <ul id="menu-link-menu" class="menu"> -->
                  <!-- <li> <a [routerLink]="['/our-team']">Our Team</a></li> -->
                  <!-- <li><a [routerLink]="['/pricing-plan']">Pricing Plan</a></li> -->
                  <!-- <li><a [routerLink]="['/faq']">FAQ</a></li> -->
                  <!-- <li><a [routerLink]="['/clients']">Clients</a></li> -->
                  <!-- <li><a [routerLink]="['/contact-us-1']">Contact Us 1</a></li>
                </ul>
              </div>
            </div>
          </div> -->
          <!-- <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
            <div class="widget">
              <h4 class="footer-title">Contact Us</h4>
              <div class="row">
                <div class="col-sm-12">
                  <ul class="iq-contact">
                    <li><a><i class="fa fa-home"></i><span>1234 North Avenue Luke Lane, South Bend, IN 360001</span></a></li>
                    <li><a href="mailto:{{ contactInfo.email }}"><i class="fa fa-envelope"></i><span>{{ contactInfo.email }}</span></a></li>
                    <li><a href="tel:{{ contactInfo.contactNumber }}"><i class="fa fa-phone"></i><span>{{ contactInfo.contactNumber }}</span></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div> -->
      <!-- </div>
    </div>
  </div> -->
  <!-- Address END -->
  <div class="copyright-footer">
    <div class="container">
      <div class="pt-3 pb-3">
        <div class="row justify-content-between">
          <div class="col-lg-6 col-md-12 text-lg-left text-md-center text-center">
            <!-- <div class="social-icone">
              <ul>
                <li class="d-inline"><a href="javascript:void(0)"><i class="fa fa-twitter"></i></a></li>
                <li class="d-inline"><a href="javascript:void(0)"><i class="fa fa-instagram"></i></a></li>
                <li class="d-inline"><a href="javascript:void(0)"><i class="fa fa-linkedin"></i></a></li>
              </ul>
            </div> -->
          </div>
          <div class="col-lg-6 col-md-12 text-lg-right text-md-center text-center">
            <span class="copyright">Copyright 2020 VAAS Cloud All Rights Reserved.</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
<!-- Footer End -->