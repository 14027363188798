import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html'
})
export class PlanComponent implements OnInit {

  // titleSectionProp: any = {
  //   class: 'text-center iq-title-box-2',
  //   title: 'Choose Pricing Plan ',
  //   subTitle: 'Pricing',
  //   titleIcon: ''
  // };

  // items: any[] = [
  //   {
  //     title: 'Basic',
  //     description: 'It is a long established fact that a reader will be distracted',
  //     price: 'Free',
  //     plan_desc: '',
//   services: [
//     { title: 'Feedback System' },
//     { title: 'Social Integration' },
//     { title: 'Push Notifications ' },
//     { title: 'Relevant App Content' },
//     { title: 'Log-in via Social Media ' }
//   ]
// },
  //   {
  //     title: 'Premium',
  //     description: 'It is a long established fact that a reader will be distracted',
  //     price: '$12',
  //     plan_desc: '/mo',
  //     active: true,
  // services: [
  //   { title: 'Feedback System' },
  //   { title: 'Social Integration' },
  //   { title: 'Push Notifications ' },
  //   { title: 'Relevant App Content' },
  //   { title: 'Log-in via Social Media ' }
  // ]
  //   },
  //   {
  //     title: 'Business',
  //     description: 'It is a long established fact that a reader will be distracted',
  //     price: '$38',
  //     plan_desc: '/mo',
  // services: [
  //   { title: 'Feedback System' },
  //   { title: 'Social Integration' },
  //   { title: 'Push Notifications' },
  //   { title: 'Relevant App Content' },
  //   { title: 'Log-in via Social Media' }
  // ]
  //   }
  // ];

  titleSectionProp: any = {
    class: 'text-center iq-title-box-2',
    title: 'Plans & Pricing ',
    subTitle: 'Pricing'
  };

  firstItems = [
    {
      title: 'VAAS-S-11',
      description: 'Cloud',
      price: '$7.04',
      plan_desc: '',
      active: 'false',
      duration: '0.6s',
      href: '#',
      services:
        [
          { title: 'Location : GER, FIN' },
          { title: 'vCPU : 1' },
          { title: 'RAM : 2 ' },
          { title: 'Disk Space : 20GB' },
          { title: 'Traffic : 20TB' },
          { title: 'OS : Linux' }
        ]
    },
    {
      title: 'VAAS-PS-11',
      description: 'Cloud',
      price: '$10.56',
      plan_desc: '',
      duration: '0.6s',
      href: '#',
      services:
        [
          { title: 'Location : GER, FIN' },
          { title: 'vCPU : 2' },
          { title: 'RAM : 2 ' },
          { title: 'Disk Space : 40GB' },
          { title: 'Traffic : 20TB' },
          { title: 'OS : Linux' }
        ]
    },
    {
      title: 'VAAS-S-21',
      description: 'Cloud',
      price: '$14.07',
      plan_desc: '',
      duration: '0.6s',
      href: '#',
      services:
        [
          { title: 'Location : GER, FIN' },
          { title: 'vCPU : 2' },
          { title: 'RAM : 4 ' },
          { title: 'Disk Space : 20GB' },
          { title: 'Traffic : 20TB' },
          { title: 'OS : Linux' }
        ]
    },
  ];

  secondItems = [
    {
      title: 'VAAS-PS-21',
      description: 'Cloud',
      price: '$19.35',
      plan_desc: '',
      duration: '0.6s',
      href: '#',
      services:
        [
          { title: 'Location : GER, FIN' },
          { title: 'vCPU : 3' },
          { title: 'RAM : 4 ' },
          { title: 'Disk Space : 80GB' },
          { title: 'Traffic : 20TB' },
          { title: 'OS : Linux' }
        ]
    },
    {
      title: 'VAAS-S-31',
      description: 'Cloud',
      price: '$24.63',
      plan_desc: '',
      duration: '0.6s',
      href: '#',
      services:
        [
          { title: 'Location : GER, FIN' },
          { title: 'vCPU : 2' },
          { title: 'RAM : 8 ' },
          { title: 'Disk Space : 80GB' },
          { title: 'Traffic : 20TB' },
          { title: 'OS : Linux' }
        ]
    },
    {
      title: 'VAAS-PS-31',
      description: 'Cloud',
      price: '$35.19',
      plan_desc: '',
      duration: '0.6s',
      href: '#',
      services:
        [
          { title: 'Location : GER, FIN' },
          { title: 'vCPU : 4' }, { title: 'RAM : 8 ' },
          { title: 'Disk Space : 160GB' },
          { title: 'Traffic : 20TB' },
          { title: 'OS : Linux' }
        ]
    }
  ];

  thirdItems = [
    {
      title: 'VAAS-S-41',
      description: 'Cloud',
      price: '$45.74',
      plan_desc: '',
      duration: '0.6s',
      href: '#',
      services: [
        { title: 'Location : GER, FIN' },
        { title: 'vCPU : 4' },
        { title: 'RAM : 16 ' },
        { title: 'Disk Space : 160GB' },
        { title: 'Traffic : 20TB' },
        { title: 'OS : Linux' }
      ]
    },
    {
      title: 'VAAS-PS-41',
      description: 'Cloud',
      price: '$66.85',
      plan_desc: '',
      duration: '0.6s',
      href: '#',
      services: [
        { title: 'Location : GER, FIN' },
        { title: 'vCPU : 8' },
        { title: 'RAM : 16 ' },
        { title: 'Disk Space : 240GB' },
        { title: 'Traffic : 20TB' },
        { title: 'OS : Linux' }
      ]
    },
    {
      title: 'VAAS-S-51',
      description: 'Cloud',
      price: '$87.97',
      plan_desc: '',
      duration: '0.6s',
      href: '#',
      services: [
        { title: 'Location : GER, FIN' },
        { title: 'vCPU : 8' },
        { title: 'RAM : 32 ' },
        { title: 'Disk Space : 240GB' },
        { title: 'Traffic : 20TB' },
        { title: 'OS : Linux' }
      ]
    },
  ];

  fourthItems = [
    {
      title: 'VAAS-PS-51',
      description: 'Cloud',
      price: '$140.75',
      plan_desc: '',
      duration: '0.6s',
      href: '#',
      services: [
        { title: 'Location : GER, FIN' },
        { title: 'vCPU : 16' },
        { title: 'RAM : 32 ' },
        { title: 'Disk Space : 360GB' },
        { title: 'Traffic : 20TB' },
        { title: 'OS : Linux' }]
    },
    {
      title: 'VAAS-D-11',
      description: 'Dedicated',
      price: '$52.78',
      plan_desc: '',
      duration: '0.6s',
      href: '#',
      services: [
        { title: 'Location : GER, FIN' },
        { title: 'vCPU : 2' },
        { title: 'RAM : 8 ' },
        { title: 'Disk Space : 80GB' },
        { title: 'Traffic : 20TB' },
        { title: 'OS : Linux' }
      ]
    },
    {
      title: 'VAAS-D-21',
      description: 'Dedicated',
      price: '$105.56',
      plan_desc: '',
      duration: '0.6s',
      href: '#',
      services: [
        { title: 'Location : GER, FIN' },
        { title: 'vCPU : 4' },
        { title: 'RAM : 16 ' },
        { title: 'Disk Space : 160GB' },
        { title: 'Traffic : 20TB' },
        { title: 'OS : Linux' }
      ]
    },
  ];

  fifthItems = [
    {
      title: 'VAAS-D-31',
      description: 'Dedicated',
      price: '$211.12',
      plan_desc: '',
      duration: '0.6s',
      href: '#',
      services: [
        { title: 'Location : GER, FIN' },
        { title: 'vCPU : 8' },
        { title: 'RAM : 32 ' },
        { title: 'Disk Space : 240GB' },
        { title: 'Traffic : 20TB' },
        { title: 'OS : Linux' }
      ]
    },
    {
      title: 'VAAS-D-41',
      description: 'Dedicated',
      price: '$422.24',
      plan_desc: '',
      duration: '0.6s',
      href: '#',
      services: [
        { title: 'Location : GER, FIN' },
        { title: 'vCPU : 16' },
        { title: 'RAM : 64 ' },
        { title: 'Disk Space : 360GB' },
        { title: 'Traffic : 20TB' },
        { title: 'OS : Linux' }
      ]
    },
    {
      title: 'VAAS-D-51',
      description: 'Dedicated',
      price: '$844.48',
      plan_desc: '',
      duration: '0.6s',
      href: '#',
      services: [
        { title: 'Location : GER, FIN' },
        { title: 'vCPU : 32' },
        { title: 'RAM : 128 ' },
        { title: 'Disk Space : 600GB' },
        { title: 'Traffic : 20TB' },
        { title: 'OS : Linux' }
      ]
    }
  ];
  constructor() { }

  ngOnInit(): void {
  }

}
